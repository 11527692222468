import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function CoachCard({animation, coachImgPath, coachName, coachDesc, updateCoach}) {
    return (
        <div className="col-md-4" style={{padding: "1rem"}}>
            <ScrollAnimation animateIn={animation} animateOnce={true}>
              <Card className="text-center">
                  <Card.Img src={coachImgPath}/>
                  <Card.Body>
                      <Card.Title>{coachName}</Card.Title>
                  <hr />
                  <Card.Text>{coachDesc}</Card.Text>
                  <Button variant="primary" onClick={() => updateCoach(coachName, coachImgPath)}>See Profile</Button>
                  </Card.Body>
                </Card>
              </ScrollAnimation> 
          </div>
    )
}

export default CoachCard