import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useFirestore } from "reactfire";
import './Admin.css';

function AdminProgramming() {

    // Mainly using useRef here so eslint doesn't make me put emptyWorkoutInfo as a trigger arg for useEffect
    const emptyWorkoutInfo = useRef({warmUp: '', skill: '', strength: '', coolDown: '', workout: '', finisher: ''});
    const prevWorkoutType = useRef('Metcon');
    const workoutData = useRef({workouts: []})

    // Set to today's date in YYYY-mm-DD string format
    const [workoutDate, setWorkoutDate] = useState(new Date().toISOString().substring(0, 10));
    // Metcon is currently the first option in the selector so it is the default
    const [workoutType, setWorkoutType] = useState('Metcon');
    // Holds the meat of the workout programming
    const [workoutInfo, setWorkoutInfo] = useState(emptyWorkoutInfo.current)

    const [fancySubmitClass, setFancySubmitClass] = useState('')
    const isCheckHidden = useRef(true);

    // equivalent of firebase.firestore(), but making use of React Context API to ensure it is a singleton
    const firestore = useFirestore();

    const findWorkoutMatchingWorkoutType = (workoutType, workouts) => {
        /**
         * Responsible for finding the workout obj & index pertaining to a workoutType
         * @param {String} workoutType the type of workout
         * @param {Object} workouts list of workout objects
         * @return {Object} provides both the workout object and index
         */
        if (workouts === undefined) {
            return undefined
        }
        for (var i = 0, l =  workouts.length; i < l; i++) {
            var workout = workouts[i];
            if (workout.workoutType === workoutType) {
                console.log(`found workoutType: ${workout.workoutType} @ index: ${i}`)
                return {workout: workout, workoutIndex: i}
            }
        }  
    }

    // Not using reactfire as I needed better control over when a DB read is neccessary.
    // e.g. we only need to read the database when the workoutDate has changed
    useEffect(() => {

        if (prevWorkoutType.current !== workoutType) {
            console.log(`using local data due to workoutType change`);
            prevWorkoutType.current = workoutType;

            let workoutObj = undefined
            if (workoutData.current !== undefined){
                workoutObj = findWorkoutMatchingWorkoutType(workoutType, workoutData.current['workouts'])
            }
            if (workoutObj !== undefined) {
                // If there is a previously logged workout for the workoutType update the workoutInfo state object
                setWorkoutInfo(workoutObj['workout'])
            }
            else {
                // If we have not previously logged a workout for the selected workoutType
                // then set the workoutInfo state object to empty
                setWorkoutInfo(emptyWorkoutInfo.current);
            }
        }
        else {
            console.log(`querying Firestore due to date change`);

            let workoutDocRef = doc(collection(firestore, "workouts"), workoutDate)
            // Each day has a doc with the date as the id, given a change of date we need to fetch new data
            // doc(collection(firestore, "workouts"), workoutDate).get().then(function(doc) {
            getDoc(workoutDocRef).then(function(doc) {
                if (doc.exists()) {
                    workoutData.current = doc.data();
                    // Check if we previously recorded a workout for the selected workoutType
                    let workoutObj = undefined
                    if (workoutData.current !== undefined){
                        workoutObj = findWorkoutMatchingWorkoutType(workoutType, workoutData.current['workouts'])
                    }
                    if (workoutObj !== undefined) {
                        // If there is a previously logged workout for the workoutType update the workoutInfo state object
                        setWorkoutInfo(workoutObj['workout'])
                    }
                    else {
                        // If we have not previously logged a workout for the selected workoutType
                        // then set the workoutInfo state object to empty
                        setWorkoutInfo(emptyWorkoutInfo.current);
                    }
                }
                else {
                    // If the doc we are looking for does not exist then workoutInfo should be empty
                    console.log(`no workout document for ${workoutDate}`)
                    setWorkoutInfo(emptyWorkoutInfo.current);
                    workoutData.current = Object.assign({},{workouts: []});
                }
            }).catch(function(error) {
                console.log(`Error getting document: ${error}`)
            });
        }
    }, [firestore, workoutDate, workoutType])

    const handleSubmit = (event) => {
        // When the form is submitted we write the corresponding workoutInfo to Firestore
        event.preventDefault();
        // If you want to see the \n newline characters
        // console.log(JSON.stringify(`workoutType: ${workoutType}\nwarmUp: ${warmUp}\nstrength: ${strength}\nworkout: ${workout}`));
        console.log(`workoutDate: ${workoutDate}\nworkoutType: ${workoutType}\nwarmUp: ${workoutInfo.warmUp}\nskill: ${workoutInfo.skill}\nstrength: ${workoutInfo.strength}\ncool down: ${workoutInfo.coolDown}\nworkout: ${workoutInfo.workout}`);

        let workoutObj = undefined
        if (workoutData.current !== undefined){
            workoutObj = findWorkoutMatchingWorkoutType(workoutType, workoutData.current['workouts'])
        }
        let workoutIndex;
        if (workoutObj !== undefined) {
            // Not only do we write to Firestore, but also we update the react state
            workoutData.current['workouts'][workoutObj['workoutIndex']] = {
                workoutType: workoutType,
                warmUp: workoutInfo.warmUp || '',
                skill: workoutInfo.skill || '',
                strength: workoutInfo.strength || '',
                coolDown: workoutInfo.coolDown || '',
                workout: workoutInfo.workout,
                finisher: workoutInfo.finisher || '',
                workoutDate: workoutDate
            }
            workoutIndex = workoutObj['workoutIndex']
        } else {
                console.log(`workoutData.current: ${workoutData.current['workouts']}`)
                console.log(`workoutData.current.length: ${workoutData.current['workouts'].length}`)
                workoutData.current['workouts'][workoutData.current['workouts'].length] = {
                    workoutType: workoutType,
                    warmUp: workoutInfo.warmUp || '',
                    skill: workoutInfo.skill || '',
                    strength: workoutInfo.strength || '',
                    coolDown: workoutInfo.coolDown || '',
                    workout: workoutInfo.workout,
                    finisher: workoutInfo.finisher || '',
                    workoutDate: workoutDate
                }
                workoutIndex = workoutData.current['workouts'].length
        }

        let newWorkoutDocRef =  doc(collection(firestore, 'workouts'), workoutDate)
        setDoc(newWorkoutDocRef, workoutData.current)
        .then(() => console.log("workout successfully added!"))
        .catch((error) => console.log(`Error writing workout document: ${error}`))

        fancySubmit();
    }

    const handleChange = e => {
        // For any new input in one of the workoutInfo text boxes we need to update that 
        // specific portion of the workoutInfo state object while maintaining the rest

        // extract the name and value elements of the Form.Control
        const {name, value} = e.target;
        setWorkoutInfo(prevWorkoutInfo => ({
            ...prevWorkoutInfo,
            [name]: value
        }));
    }

    function fancySubmit(){
        setFancySubmitClass('onSubmitBtnClick');
        setTimeout(function(){
            isCheckHidden.current = false;
            setFancySubmitClass('submitBtnValidate');
            setTimeout(function(){
                isCheckHidden.current = true;
                setFancySubmitClass('');
            }, 1250)
        }, 2250)
    }

    return (
        <div className="wrapper py-4">
            <h1 className="text-center">Workout Addition Form</h1>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group controlId="datepicker" as={Col}>
                        <Form.Label>Workout Date</Form.Label>
                        <Form.Control type="date" required value={workoutDate} onChange={e => {console.log("in workoutDate onchange");setWorkoutDate(e.target.value)}}/>
                    </Form.Group>

                    <Form.Group controlId="workoutType" as={Col}>
                        <Form.Label>Workout Type</Form.Label>
                        <Form.Select required value={workoutType} onChange={e => setWorkoutType(e.target.value)}>
                        <option>Metcon</option>
                        <option>Weightlifting</option>
                        <option>Mobility</option>
                        <option>Kettlebell</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="warmUp" as={Col}>
                        <Form.Label>WARM-UP</Form.Label>
                        <Form.Control as="textarea" rows={12} name="warmUp" value={workoutInfo.warmUp} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="skill" as={Col}>
                        <Form.Label>SKILL</Form.Label>
                        <Form.Control as="textarea" rows={12} name="skill" value={workoutInfo.skill} onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="strength" as={Col}>
                        <Form.Label>STRENGTH</Form.Label>
                        <Form.Control as="textarea" rows={12} name="strength" value={workoutInfo.strength} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="coolDown" as={Col}>
                        <Form.Label>COOL DOWN</Form.Label>
                        <Form.Control as="textarea" rows={12} name="coolDown" value={workoutInfo.coolDown} onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="workout" as={Col}>
                        <Form.Label>WORKOUT</Form.Label>
                        <Form.Control as="textarea" rows={12} name="workout" required value={workoutInfo.workout} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="finisher" as={Col}>
                        <Form.Label>FINISHER</Form.Label>
                        <Form.Control as="textarea" rows={12} name="finisher" value={workoutInfo.finisher} onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <div className="submitBtnContainer">
                    <button  type="submit" className={`submitBtn ${fancySubmitClass}`}>{isCheckHidden.current ? "SUBMIT" : <FontAwesomeIcon icon={faCheck} color="white"/>}</button>
                </div>
            </Form>
        </div>
    )
}

export default AdminProgramming
