import React from "react";
import CalloutText from "../common/CalloutText";
import TwoColumn from "../common/TwoColumn";
import "./About.css";
import AboutGoalPreface from "./AboutGoalPreface";

function About() {
  return (
    <div className="wrapper">
      <AboutGoalPreface />
      <TwoColumn 
      title="WE ARE BRUIN FITNESS" 
      textBlocks={["We at Bruin Fitness pride ourselves on changing the lives of as many individuals as possible. We've taken years of planning and developing Bruin Fitness layout for the most optimal fitness experience possible for our fantastic members.",
      "Our goal is to have a one on one personal experience for our members who we often refer to as our extended family. Learn more about us by getting to know and love our facility."]} 
      imgLoc="/images/tomburke.jpg"/>
      {/* Divider */}
      <hr className="my-4" />
      <TwoColumn 
      title="A Message From The Bruin Fitness Founders" 
      textBlocks={["\"Fitness is a lifestyle. We believe that healthy bodies make happy people. We have been enthusiasic about fitness our whole lives and are happy to share our experiences with all of our members. We strive to make fitness fun, provide a place for competitors, while still making beginners feel welcome. We hope Bruin Fitness is a good fit for you!\""]} 
      imgLoc="/images/founders.jpg"
      author="-Tom B. & Margaux C., Co-Founders"
      isPicOnLeft={true}/>
      <CalloutText />
    </div>
  );
}

export default About;
