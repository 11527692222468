import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavBar from "react-bootstrap/NavBar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from 'react-router-bootstrap';
import { useAuth, useSigninCheck } from 'reactfire';
import "./NavigationBar.css";



const signOut = auth => auth.signOut().then(() => console.log('signed out'));

function NavigationBar() {

  const auth = useAuth();
  // const { status: adminStatus, data: adminSignInCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });
  const { status, data: signInCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });

  if (status === 'loading') {
    return <div></div>;
  }

  return (
    <NavBar bg="light" variant="light" expand="md" sticky="top">
      <Container fluid>
        <LinkContainer to="/">
          <NavBar.Brand>
            <img
              src="/images/BruinFitness40.png"
              alt="BruinFitness logo"
            />
            {/* {' '}
            Bruin Fitness */}
          </NavBar.Brand>
        </LinkContainer>
        <NavBar.Toggle aria-controls="basic-navbar-nav" />
        <NavBar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer exact to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            {/* renderMenuOnMount renders the menu in the DOM before the first time it is shown */}
            <NavDropdown
              title="About"
              id="nav-dropdown"
              renderMenuOnMount={true}>
              <LinkContainer to="/about">
                <NavDropdown.Item>About Us</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/team">
                <NavDropdown.Item>Team</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/FAQ">
                <NavDropdown.Item>FAQ</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/schedule">
              <Nav.Link>Schedule</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/pricing">
              <Nav.Link>Pricing</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link>Contact</Nav.Link>
            </LinkContainer>
            
            {signInCheckResult.hasRequiredClaims === true && 
              (<NavDropdown
                title="Admin"
                renderMenuOnMount={true}>
                <LinkContainer to="/admin/programming">
                  <NavDropdown.Item>Programming</NavDropdown.Item>
                </LinkContainer>
                {/* <NavDropdown.Item href="/admin/schedule">Schedule</NavDropdown.Item> */}
              </NavDropdown>)
            }
            
            {signInCheckResult.signedIn === true ? <div>
              <NavDropdown
                title={
                  <div style={{display: "inline-block"}}>
                    < FontAwesomeIcon icon={faUserCircle} color="black" size="lg"/>
                      {/* <img className="thumbnail-image" 
                          src={src} 
                          alt="user pic"
                      /> */}
                  </div>
              } 
                renderMenuOnMount={true}>
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={() => signOut(auth)}>Log Out</NavDropdown.Item>
              </NavDropdown>
              </div> : <LinkContainer to="/login"><Nav.Link>Member Login</Nav.Link></LinkContainer>}
          </Nav>
        </NavBar.Collapse>
      </Container>
    </NavBar>
  );
}

export default NavigationBar;
