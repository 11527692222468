import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MyContext } from "../../providers/MyProvider";
import "./ScheduleDatePicker.css";

function ScheduleDatePicker() {
  const context = useContext(MyContext);
  const [date, setDate] = useState(new Date());
  const [initialMount, setInitialMount] = useState(true);

  /** This feels a bit hacky, but was the only way I could figure out how to get the desired behavior
   * I want the react context API to reset its state when this component mounts otherwise the datepicker
   * will be equal to today off a new ScheduleDatePicker component mount, but the others won't
   * So I created a boolean that specfically keeps track of the first mount to avoid the infinte loop that
   * the useEffect and handleChange encounter if not.
   * */
  useEffect(() => {
      if (initialMount) {
      // Change the date displayed in the UI
      context.changeDate(new Date());
      //Change the date for the Firestore collection ref
      context.changeFirestoreDate(new Date());
      setInitialMount(false);
  }}, [context, initialMount])

  const handleChange = (date) => {
    // Change the date displayed in the UI
    context.changeDate(date);
    //Change the date for the Firestore collection ref
    context.changeFirestoreDate(date);
    setDate(date);
  };

  let oneWeekBack = new Date();
  let oneWeekForward = new Date();
  oneWeekBack.setDate(oneWeekBack.getDate() - 7);
  oneWeekForward.setDate(oneWeekForward.getDate() + 7);

  return (
    <div className="datepicker-div">
      <DatePicker
        selected={date}
        onChange={handleChange}
        dateFormat="MMMM d, yyyy"
        minDate={oneWeekBack}
        maxDate={oneWeekForward}
        closeOnScroll={true}
        // placeholderText="Placeholder Text 🗓️"
        // className="text-center"
        // excludeDates={[new Date(), subDays(new Date(), 1)]}
      />
    </div>
  );
}

export default ScheduleDatePicker;