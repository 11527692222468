import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import "./App.css";
import About from "./components/about/About";
import AdminProgramming from "./components/admin/AdminProgramming";
import Footer from "./components/common/Footer";
import NavigationBar from "./components/common/NavigationBar";
import Contact from "./components/contact/Contact";
import FAQ from "./components/faq/FAQ";
import Home from "./components/home/Home";
import Pricing from "./components/pricing/Pricing";
import Profile from "./components/profile/Profile";
import Schedule from "./components/schedule/Schedule";
import SignInForm from "./components/signin-form/SignInForm";
import Team from "./components/team/Team";
import "./index.css";
import MyProvider from "./providers/MyProvider";

function App() {
  const { status, data: signInCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });

  // check the loading status
  if (status === 'loading') {
    return <div></div>;
  }

  return (

        <MyProvider>
          <div className="content-holder">
          <div className="content">
            <Router>
            <NavigationBar />
                <Switch>
                  <Route path="/about" component={About} />
                  <Route path="/team" component={Team} />
                  <Route path="/FAQ" component={FAQ} />
                  <Route path="/schedule" component={Schedule} />
                  <Route path="/pricing" component={Pricing} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/login" component={SignInForm} />

                  {/* in the case someone signs out on these specific URL paths we'll send them back to the home page */}
                  <Route path="/profile">
                    {signInCheckResult.signedIn === true ? <Profile /> : <Home />}
                  </Route>
                  <Route path="/admin/programming">
                    {signInCheckResult.hasRequiredClaims === true ? <AdminProgramming /> : <Home />}
                  </Route>

                  {/* default route & Home page */}
                  <Route path="/" component={Home} />
                </Switch>
            </Router>
            </div>
            <Footer />
          </div>
        </MyProvider>
  );
}

export default App;
