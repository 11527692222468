import { doc } from "firebase/firestore";
import React from "react";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import "./ScheduleTable.css";

function ScheduleTable() {
  const firestore = useFirestore();
  // Get a reference to the schedule collection of interest
  const scheduleRef = doc(firestore, "schedules/Redwood City/generalSchedule/u4sAcQxzfs3tfYEfZWZc");  
  const { status, data: scheduleData } = useFirestoreDocDataOnce(scheduleRef);
  const headers = ["Workout Type", "Day", "Time"];

  if (status === 'loading') {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
  );
  }

  return (
    <div className="table-wrapper-scroll-y my-custom-scrollbar">
        {/* table-sm makes all table cells small */}
      <table className="table table-bordered table-hover table-sm">
        <TableHeader headers={headers}></TableHeader>
        <TableBody headers={headers} rows={scheduleData['classes']}></TableBody>
      </table>
    </div>
  );
}

const TableHeader = (props) => {
  const { headers } = props;
  return (
    <thead className="table-dark" key="header-1">
      <tr key="header-0">
        {headers &&
          headers.map((value, index) => {
            return (
              <th key={index}>
                <div>{value}</div>
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

const TableBody = (props) => {
  const { headers, rows } = props;
  const columns = headers ? headers.length : 0;
  const showSpinner = rows === null;

  function buildRow(row, headers) {
    return (
      // Wonky way of creating a unique key per row, but works for now
      <tr className="tr-hover-control" key={row["Workout Type"] + row["Day"] + row["Time"]}>
        {headers.map((value, index) => {
          return <td key={index}>{row[value]}</td>;
        })}
      </tr>
    );
  }

  return (
    <tbody className="table-bordered table-dark tbody-hover-control">
      {showSpinner && (
        <tr className="tr-hover-control" key="spinner-0">
          <td colSpan={columns} className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </td>
        </tr>
      )}
      {!showSpinner &&
        rows &&
        rows.map((value) => {
          return buildRow(value, headers);
        })}
    </tbody>
  );
};

export default ScheduleTable;

