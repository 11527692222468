import React, { useRef, useState } from 'react';
import Container from "react-bootstrap/Container";
import CalloutText from '../common/CalloutText';
import TwoColumn from "../common/TwoColumn";
import WebPageHeader from '../common/WebPageHeader';
import CoachCard from './CoachCard';

function Team() {

    // Used to keep track of the selected coach if there is one
    const [selectedCoachName, setSelectedCoachName] = useState('');
    const [selectedCoachImgPath, setSelectedCoachImgPath] = useState('');
    // Coaches are selected in a child component so this function allows us to update this component's state from a child component
    function updateCoach(coachName, imgPath) {
        setSelectedCoachName(coachName);
        setSelectedCoachImgPath(imgPath);
        executeScroll();
    }
    const coachDetailRef = useRef(null);
    const executeScroll = () => coachDetailRef.current.scrollIntoView();
    // TODO: Later move these to a database and query for the details based off username
    const coachDetails = {
        'Tom Burke': 
            {coachDesc: ["We at Bruin Fitness pride ourselves on changing the lives of as many individuals as possible. We've taken years of planning and developing Bruin Fitness layout for the most optimal fitness experience possible for our fantastic members.",
            "Our goal is to have a one on one personal experience for our members who we often refer to as our extended family. Learn more about us by getting to know and love our facility."]},
        'Margaux Carle': 
            {coachDesc: ["Fitness is a lifestyle. We believe that healthy bodies make happy people. We have been enthusiasic about fitness our whole lives and are happy to share our experiences with all of our members. We strive to make fitness fun, provide a place for competitors, while still making beginners feel welcome. We hope Bruin Fitness is a good fit for you!"]},
        'Tom Again': 
            {coachDesc: ["We at Bruin Fitness pride ourselves on changing the lives of as many individuals as possible. We've taken years of planning and developing Bruin Fitness layout for the most optimal fitness experience possible for our fantastic members.",
            "Our goal is to have a one on one personal experience for our members who we often refer to as our extended family. Learn more about us by getting to know and love our facility."]}
        }

    return (
        <div className="wrapper pt-2">
            <WebPageHeader header="Our Team" />
            <Container fluid>
                <div className="row padding">
                    <CoachCard 
                    animation="slideInLeft" 
                    coachImgPath="/images/tomburke.jpg" 
                    coachName="Tom Burke" 
                    coachDesc="Tom is an athelete from several backgrounds including kung
                            fu, weightlifting, and gymnastics"
                    updateCoach={updateCoach}/>
                    
                    <CoachCard 
                    animation="slideInUp" 
                    coachImgPath="/images/margauxcarle.jpg" 
                    coachName="Margaux Carle" 
                    coachDesc="Margaux is the head CrossFit coach also boasting an
                    impressive background in nutrition"
                    updateCoach={updateCoach}/>

                    <CoachCard 
                    animation="slideInRight" 
                    coachImgPath="/images/tomburke.jpg" 
                    coachName="Tom Again" 
                    coachDesc="Tom is an athelete from several backgrounds including kung
                            fu, weightlifting, and gymnastics"
                    updateCoach={updateCoach}/>
                </div>
                <hr />
                <div ref={coachDetailRef}>
                {selectedCoachName && 
                <TwoColumn 
                title={selectedCoachName}
                isPicOnLeft={true}
                textBlocks={coachDetails[selectedCoachName]["coachDesc"]} 
                imgLoc={selectedCoachImgPath}/>
                }
                </div>
            </Container>
            <CalloutText />
        </div>
    )
}

export default Team
