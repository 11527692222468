import React from 'react';
import './LoadingSpinner.css';

export const LoadingSpinner = () => {
    return (
        <div className="loading">Loading&#8230;</div>
    );
  };

export default LoadingSpinner
