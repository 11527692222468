import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import React from 'react';
import Card from "react-bootstrap/Card";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import { useAuth } from 'reactfire';
import "./SignInForm.css";

var config = {
  apiKey: "AIzaSyAVSJR_4lVFYaFSHIDU_FkKP83-_aZUvI4",
  authDomain: "bruinfitnessprod.firebaseapp.com",
  databaseURL: "https://bruinfitnessprod.firebaseio.com",
  projectId: "bruinfitnessprod",
  storageBucket: "bruinfitnessprod.appspot.com",
  messagingSenderId: "557271418042",
  appId: "1:557271418042:web:d3d583194db226f21e2e83",
  measurementId: "G-3HN3QFT3Q0",
};

firebase.initializeApp(config);

const SignInForm = () => {
    // const auth = useAuth;
  
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // Whether the displayName should be displayed in the sign up page
          requireDisplayName: false,
          disableSignUp: {
            status: true,
            adminEmail: "bruinfitnessgym@gmail.com",
            // helpLink: "https://bruinfitness.com/"
          }
        }
        ],
        // Redirect to / (the home page) after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/'
    };
    return (
      <div className="fullpage">
        <div className="wrapper">
          <Card bg="dark" text="light" border="dark" className="text-center sign-in-card">
            <Card.Body>
              <Card.Title>Member Sign In</Card.Title>
              <Card.Text className="signInCardText">
                Only members can sign in, to get an account come visit our gym!
              </Card.Text>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </Card.Body>
          </Card>  
        </div>
        </div>
    );
  };

export default SignInForm
