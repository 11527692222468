import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { httpsCallable } from "firebase/functions";
import React, { useRef, useState } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Col from "react-bootstrap/Col";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useFunctions } from "reactfire";
import "./ContactForm.css";

/**
 * The contact page is responsible for taking user input in a form, writing that to Firestore which
 * then triggers a Firebase mail trigger extension to send an email to the Bruin Fitness team
 * indicating a new potentially interested user. Our team will respond within 48 hours answering any
 * questions they may have and how to get them started.
 *
 * To set up a SMTP connection URI you need to set up an SMTP Relay on Sendgrid: https://app.sendgrid.com/guide/integrate
 * When configuring a SMTP connection URI for your Firebase extension it should take the form:
 * smtps://apikey:SUPER_COMPLEX_PASSWORD@smtp.sendgrid.net:465
 *
 * Sendgrid requires you to verify your sender ID via single sender verification or domain authentication for this extension to work: https://sendgrid.com/docs/for-developers/sending-email/sender-identity/ . Meaning that the FROM address set in the Firebase Extension configuration needs to be verified at the above address.
 * The Free SendGrid account allows for 100 emails/day, which should be enough quota for this app, If it is not that is probably a good thing.. or we have some spammers we need to limit.
 *
 * If using gmail / GSuite directly make sure to enable the two below (not recommended and not what this does...):
 * https://www.google.com/settings/security/lesssecureapps
 * https://accounts.google.com/DisplayUnlockCaptcha
 */

function ContactForm() {

  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [fancySubmitClass, setFancySubmitClass] = useState('');
  const isCheckHidden = useRef(true);
  const functions = useFunctions();

  const resetForm = () => {
    setFormName('');
    setFormEmail('');
    setFormMessage('');
  }

  const handleSubmit = (event) => {
    // When the form is submitted we write the corresponding workoutInfo to Firestore
    event.preventDefault();
    // Call a callable GCF instead of performing the write on the client
    var contactUsMessage = httpsCallable(functions, 'contactUsMessage');
    contactUsMessage({ name: formName, email: formEmail, message: formMessage })
      .then((result) => {
        // Read result of the Cloud Function.
        console.debug(`docId: ${JSON.stringify(result)}`);
      })
      .catch((error) => {
        // Getting the Error details.
        var errorCode = error.code;
        var errorMessage = error.message;
        var errorDetails = error.details;
        console.log(`Failed to submit contact form information to database`);
        console.log(`error code: ${errorCode}`);
        console.log(`error message: ${errorMessage}`);
        console.log(`error details: ${errorDetails}`);
        alert("Unable to submit contact info message, please try again or reach out directly to bruinfitnessgym@gmail.com");
      });

    // Reset the controlled form
    resetForm();
    // Trigger submit button animation
    fancySubmit();
  }

  function fancySubmit(){
    setFancySubmitClass('onSubmitBtnClick');
    setTimeout(function(){
        isCheckHidden.current = false;
        setFancySubmitClass('submitBtnValidate');
        setTimeout(function(){
            isCheckHidden.current = true;
            setFancySubmitClass('');
        }, 1250)
    }, 2250)
}

    return (
        <div className="col-md-7">
          <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
            <h4 className="text-left py-3">We'd be happy to hear from you!</h4>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group controlId="form_name" as={Col}>
                <FloatingLabel
                            controlId="floatingInputName"
                            label="Full Name">
                  {/* <Form.Label>Full Name</Form.Label> */}
                  <Form.Control type="text" required value={formName} placeholder='Enter your name.' onChange={e => {setFormName(e.target.value)}}/>
                </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group controlId="form_email" as={Col}>
                <FloatingLabel
                            controlId="floatingInputEmail"
                            label="Email">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control type="email" required value={formEmail} placeholder='Enter your email.' onChange={e => {setFormEmail(e.target.value)}}/>
                </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group controlId="form_message" as={Col}>
                {/* <FloatingLabel
                            controlId="floatingInputMessage"
                            label="Message Us!"> */}
                  <Form.Label>Message Us!</Form.Label>
                  {/* style={{ height: '100px' }} */}
                  <Form.Control as="textarea" required rows={4} value={formMessage} placeholder='Add your message.' onChange={e => {setFormMessage(e.target.value)}}/>
                {/* </FloatingLabel> */}
                </Form.Group>
              </Row>
              <div className="submitBtnContainer">
                    <button  type="submit" className={`submitBtn ${fancySubmitClass}`}>{isCheckHidden.current ? "SUBMIT" : <FontAwesomeIcon icon={faCheck} color="white"/>}</button>
              </div>
            </Form>
          </ScrollAnimation>
        </div>
    )
}

export default ContactForm