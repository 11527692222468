import { getAuth } from 'firebase/auth'; // Firebase v9+
import { getFirestore } from 'firebase/firestore'; // Firebase v9+
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import React from 'react';
import { AuthProvider, FirestoreProvider, FunctionsProvider, StorageProvider, useFirebaseApp } from "reactfire";
import App from "./App";

function AppWrapper() {
    const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

    // initialize Firestore, Storage, and Auth with the normal Firebase SDK functions
    const auth = getAuth(app);
    const firestoreInstance = getFirestore(app);
    const storage = getStorage(app)
    const functionInstance = getFunctions(app)
  return (
  <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
          <StorageProvider sdk={storage}>
              <FunctionsProvider sdk={functionInstance}>
                <App />
              </FunctionsProvider>
            </StorageProvider>
        </FirestoreProvider>
    </AuthProvider>);
}

export default AppWrapper;
