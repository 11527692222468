import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

function TwoColumn({title, textBlocks, imgLoc, isPicOnLeft, author}) {

    if (isPicOnLeft) {
        return (
        <React.Fragment>
          <div className="row px-4">
          <div className="col-md-5 p-4 my-auto">
              <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
                <img src={imgLoc} className="img-fluid" alt="" />
              </ScrollAnimation>
            </div>
            <div className="col-md-7 p-4 text-center">
              <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
                <h4>{title}</h4>
                <hr className="w-25 mt-3 mb-4 mx-auto" />
                {textBlocks.map((textBlock) => {
                    return <p key={textBlock}>{textBlock}</p>
                })}
                {author && <h5>{author}</h5>}   
              </ScrollAnimation>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
        <React.Fragment>
          <div className="row px-4">
            <div className="col-md-7 p-4 text-center">
              <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
                <h4>{title}</h4>
                <hr className="w-25 mt-3 mb-4 mx-auto" />
                {textBlocks.map((textBlock) => {
                    return <p key={textBlock}>{textBlock}</p>
                })}
                {author && <h5>{author}</h5>}
              </ScrollAnimation>
            </div>
            <div className="col-md-5 p-4 my-auto">
              <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
                <img src={imgLoc} className="img-fluid" alt="" />
              </ScrollAnimation>
            </div>
          </div>
        </React.Fragment>
      );
}

export default TwoColumn
