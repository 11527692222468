import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import CalloutText from '../common/CalloutText';
import WebPageHeader from '../common/WebPageHeader';
import "./FAQ.css";

function FAQ() {
    return (
            <div className="wrapper pt-2">
                <WebPageHeader header="FREQUENTLY ASKED QUESTIONS"/>
                <FAQAccordion />
                <CalloutText />
            </div>
    )
}

function FAQAccordion() {
    return (
        <React.Fragment>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>What does a membership include?</Accordion.Header>
                    <Accordion.Body>
                        You get an all access pass to weights and gains
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>What if I don't know how to do the movements?</Accordion.Header>
                    <Accordion.Body>
                        Our coaches are equip to get you up to speed before you know it. 
                        If you don't feel quite ready for a movement our coaches will provide scaling options to ensure you still get the workout you intended for.
                        Alternatively, you can schedule private sessions with our coaches to get you feeling good in no time!
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>What if I don't know how to do the movements?</Accordion.Header>
                    <Accordion.Body>
                        Our coaches are equip to get you up to speed before you know it. 
                        If you don't feel quite ready for a movement our coaches will provide scaling options to ensure you still get the workout you intended for.
                        Alternatively, you can schedule private sessions with our coaches to get you feeling good in no time!
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </React.Fragment>
    )
}

export default FAQ
