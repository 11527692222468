import { faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import 'firebase/compat/firestore';
import { arrayRemove, arrayUnion, collection, doc, increment, orderBy, query, runTransaction } from "firebase/firestore";
import React, { useContext, useState } from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { MyContext } from "../../providers/MyProvider";
import "./ReservationTable.css";

function ReservationTable() {
  // equivalent of firebase.firestore(), but making use of React Context API to ensure it is a singleton
  const firestore = useFirestore();
  // This context object holds the state from the DatePicker component which sets the date
  const context = useContext(MyContext);
  // Can't use .doc() after .orderBy() is applied to a collectionRef
  // Get a reference to the schedule collection for the currently selected date
  const reservationRef = collection(firestore, 
    `schedules/Redwood City/dates/${context.state.firestoreDate}/classes`
  )
  const reservationQuery = query(reservationRef, orderBy('classTimestamp', 'asc'));
  // Soon to be used when testing is over and reservation info is added daily/weekly
  // Verified that this updates when the datepicker date is changed
  const unorderedResevationRef = collection( firestore,
    `schedules/Redwood City/dates/${context.state.firestoreDate}/classes`
  );
  // This is adding a snapshot listener to this collection in React's useEffect method
  // under the hood. This removes the need for a `setReservationData` method call.
  // The idField param is what sets the doc.id value equal to the document's id, o/w it is not there by default
  // This will also handle listener detachment, kudos to the reactfire team!
  // TODO: what sort of error handling can be put in place here?
  const reservationData = useFirestoreCollectionData(reservationQuery, { idField: 'id' });
  // Of the documents keys, these are the ones we want as columns in the table
  const headers = ["time", "workoutType"];
  // Subscribe to auth updates (i.e. onAuthStateChanged())
  const { status: userStatus, data: user, hasEmitted } = useUser();

  function checkIsReserved(reservedUsers, uid) {
    /**
     * Summary: Checks if user is reserved in a class to prevent the race condition when someone hammers on the reserve button
     * @param {[Object]} reservedUsers Array of reserved user objects containing the user's uid
     * @param {String} uid the uid of the user currently signed into the application
     */
    return reservedUsers.some(reservedUser => reservedUser.uid === uid)
  }

  function getReservation(reservedUsers, uid) {
    /**
     * Summary: Gets the reservedUser given a uid
     * @param {[Object]} reservedUsers Array of reserved user objects containing the user's uid
     * @param {String} uid the uid of the user currently signed into the application
     */
     return reservedUsers.find(reservedUser => reservedUser.uid === uid);
  }

  async function removeReservation(docId) {
    /**
    Summary: Atomically decrements the reservationCnt and removes the signed in user from the class
    @param {String} docId Firestore document id of class being incremented.
    @return {} null
    */
    console.debug(`removing reservation for ${user.uid}`);
    // Get a Firestore reference to the class within the classes collection for the day selected
    let docRef = doc(unorderedResevationRef, docId);
    const decrement = increment(-1);

    // Optional TODO: this transaction may be able to be replaced by clever security rule usage
    try {
      await runTransaction(firestore, async (transaction) => {
        const reservationDoc = await transaction.get(docRef);
        if (!reservationDoc.exists()) {
          throw new Error("Document does not exist!");
        }
        let reservedUser = getReservation(reservationDoc.data().reservedUsers, user.uid)

          // check that the user is not already reserved in the class
          if (reservedUser === undefined){
            console.debug(`can't unreserve a user in a class that is not in the class`)
            throw new Error(`User ${user.uid} not in class ${docId} so they couldn't be unreserved`)
          }
          const removeUser = arrayRemove({uid: reservedUser.uid, displayName: reservedUser.displayName, photoURL: reservedUser.photoURL});

          // Atomically remove a user from the "reservedUsers" array field.
          // Atomically decrement the reservationCnt of the class by 1.
          transaction.update(docRef, {
            reservationCnt: decrement,
            reservedUsers: removeUser,
          });
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
  }

  async function incrementReservation(docId) {
    /** 
    Summary: Attempts a transaction to add a user and increment the reservationCnt of a class
    @param {String} docId Firestore document id of class being incremented.
    @return {Promise<T>} 
    */
    // Get a reference to the class doc being updated
    let docRef = doc(unorderedResevationRef, docId);
    const incrementReservationCnt = increment(1);
    // Add the user with display name and photo so we can view who reserved a spot in a class
    const addUser = arrayUnion({uid: user.uid, displayName: user.displayName, photoURL: user.photoURL});

    // using a transaction to ensure we don't exceed the max number of reservations
    // Optional TODO: this transaction may be able to be replaced by clever security rule usage
    try {
      await runTransaction(firestore, async (transaction) => {
        // This code may get re-run multiple times (max 5 times) if there are conflicts.
        const reservationDoc = await transaction.get(docRef);
        if (!reservationDoc.exists()) {
          throw new Error("Document does not exist!");
        }
        // check that the user is not already reserved in the class
        if (checkIsReserved(reservationDoc.data().reservedUsers, user.uid)){
          console.debug(`can't reserve a user in a class that is already in the class`)
          throw new Error(`User ${user.uid} already in class ${docId} they are trying to reserve into`)
        }
        // Get the current reservation count
        if (reservationDoc.data().reservationCnt >= reservationDoc.data().maxReservationCnt) {
          // If the class is full, we need to alert the user that their reservation attempt was unsuccessful
          alert("Sorry the class is now full. Please try reserving a different time slot");
        } else {
          // If there is still space left in the class then add the user and increment the count
          // Atomically add a user to the "reservedUsers" array field.
          // Atomically increment the reservationCnt of the class by 1.
          transaction.update(docRef, {
            reservationCnt: incrementReservationCnt,
            reservedUsers: addUser,
          });
        }
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
  }

  if (userStatus === 'loading' || hasEmitted === false) {
    return <></>;
  }

  return (
    <React.Fragment>
      {Array.isArray(reservationData.data) && reservationData.data.length
      ? (
        <div className="table-wrapper-scroll-y reservation-table-scrollbar">
          <table className="table table-bordered table-hover table-sm">
            <thead className="table-dark">
              <tr>
                <th className="th-z-index" colSpan="3">{context.state.scheduleDate} Classes</th>
              </tr>
            </thead>
            <tbody className="tbody-hover-control table-dark">
              {reservationData.data.map((data) => {
                return <TableBody
                  key={data.id}
                  headers={headers}
                  reservationData={data}
                  userId={user?.uid}
                  incrementReservation={incrementReservation}
                  removeReservation={removeReservation} />
              }
              )}
            </tbody> 
          </table>
        </div>
        ) : 
        (
          <div className="text-center">
          <p>Oops, no classes uploaded yet for {context.state.scheduleDate}! </p>
          </div>
        )}
    </React.Fragment>
  );
}

const TableBody = ({
  headers,
  reservationData,
  userId,
  incrementReservation,
  removeReservation,
}) => {
  // Dynamically determine how many columns are needed by looking at the list of headers
  const columns = headers ? headers.length : 0;
  // While we wait for the database to provide the required data this will control a spinner being displayed as we wait
  const showSpinner = reservationData === null;

  function buildReservationButton(row) {
    /** 
    Summary: Responsible for building the reservation button and handling the different UI, given state passed to it in the `row`
    @param {Object} row Firestore document data pertaining to a class w/ reservation data.
    @return {Object} HTML to be rendered. Specifically the reservation button placed in the table body's column 
    */
    // check if the class has past and indicate that reservations have been closed
    if (row["classTimestamp"].toDate() < Date.now()) {
      return (
        <div className="col-md-5">
          <small style={{ color: 'gray'}}>Reservations Closed</small>
          <br />
          {row["reservationCnt"] < row["maxReservationCnt"] ? (
            <div>
              <small>{row["reservationCnt"]} of {row["maxReservationCnt"]} reserved</small>
            </div>
          ) : (
              <small>Class Full</small>
            )}
        </div>
      )
    }
    // If the user has reserved a spot in the class
    else if (row["reservedUsers"].some(user => user.uid === userId)) {
      // If they are in the class we need to allow them to leave the class if they can't make it
      return (
        <div className="col-md-5">
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => removeReservation(row["id"])}
          >
            Leave Class
          </button>
          <br />
          {row["reservationCnt"] < row["maxReservationCnt"] ? (
            <div>
              <small>{row["reservationCnt"]} of {row["maxReservationCnt"]} reserved</small>
            </div>
          ) : (
              <small>Class Full</small>
            )}
        </div>
      );
    }
    // If the user has not reserved a spot in the class
    else {
      if (row["reservationCnt"] < row["maxReservationCnt"]) {
        // If they are not in the class and there are still spaces available then prompt them with a button to reserve a spot
        return (
          <div className="col-md-5">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => incrementReservation(row["id"])}
            >
              Reserve
            </button>
            <br />
            <div>
              <small>{row["reservationCnt"]} of {row["maxReservationCnt"]} reserved</small>
            </div>
          </div>
        );
      } else {
        return (
          // If they are not in the class and the class is full we remove the button and mark it as full
          <div className="col-md-5">Class Full</div>
        );
      }
    }
  }

  function buildRow(row, headers) {
    /**
    Summary: Responsible for building individual table rows given a Firestore document representing a class
    @param: {Object} row Firestore document data pertaining to a class w/ reservation data.
    @param: {Array} headers List of all the column headers for the reservation table.
    @return {Object} HTML to be rendered. Specifically a full HTML table row w/ reservation data.
    */
    return (
      // Wonky way of creating a unique key per row, but works for now
      <tr className="tr-hover-control" key={row["workoutType"] + row["time"]}>
        {headers.map((value, index) => {
          return (
            <td key={index}>
              {/* if it is the column with workout type, then we need to render resevation btn etc */}
              {value === "workoutType" && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-5 vcenter">{row[value]}</div>
                    {buildReservationButton(row)}
                    <div className="col-md-2">
                      <AttendanceOffCanvas attendees={row['reservedUsers']}/>
                    </div>
                  </div>
                </div>
              )}
              {value !== "workoutType" && (
                <React.Fragment>{row[value]}</React.Fragment>
              )}
            </td>
          );
        })}
      </tr>
    );
  }

  return (
    <React.Fragment>
      {showSpinner && (
        <tr className="tr-hover-control" key="spinner-0">
          <td colSpan={columns} className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </td>
        </tr>
      )}
      {!showSpinner &&
        reservationData &&
        buildRow(reservationData, headers)}
    </React.Fragment>
  );
};


const renderTooltip = (props) => (
  <Tooltip id="attendance-tooltip" {...props}>
    Attendance
  </Tooltip>
);

function AttendanceOffCanvas({attendees}) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function buildAttendeeList(){
    return (
      <React.Fragment>
      {attendees.length !== 0 ? 
        (
          <Offcanvas.Body>
          <Container fluid>
            {attendees.map((attendeeData) => {
              return (
                <Row className="pb-2" key={attendeeData.displayName}>
                <Col>
                  {attendeeData.photoURL ? <Image src={attendeeData.photoURL} roundedCircle style={{ width: '4em', height: '4em' }}/> : < FontAwesomeIcon icon={faUserCircle} color="black" size="4x"/>}
                </Col>
                <Col xs={9}>
                <p>{attendeeData.displayName}</p>
                </Col>
              </Row>
              )
            })
            }
          </Container>
        </Offcanvas.Body>
        )
        :
        (
          <Offcanvas.Body>
            <p>No one reserved a spot yet!</p>
          </Offcanvas.Body>
        )
      }
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <span>
        < FontAwesomeIcon icon={faUser} size="lg" onClick={handleShow} className="user-icon"/>
        </span>
      </OverlayTrigger>

      <Offcanvas show={show} onHide={handleClose} scroll={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Attendees</Offcanvas.Title>
        </Offcanvas.Header>
        {buildAttendeeList()}
      </Offcanvas>
    </React.Fragment>
  );
}

export default ReservationTable;
