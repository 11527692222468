import { faCheck, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateProfile } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import { useStorage, useStorageTask, useUser } from "reactfire";
import LoadingSpinner from '../common/LoadingSpinner';

function Profile() {

    const { status: userStatus, data: user, hasEmitted } = useUser();
    // Holds a GCS download URL when it is available
    const [proPic, setProPic] = useState('');
    const [displayName, setDisplayName] = useState('');
    // This is used to pass to the ImageUploadButton Component so we can update the state of this component from there
    const [proPicChanged, setProPicChanged] = useState(false);
    function updateProPicChanged(){
        setProPicChanged(true)
    }
    const storage = useStorage();

    const [fancySubmitClass, setFancySubmitClass] = useState('')
    const isCheckHidden = useRef(true);
    function fancySubmit(){
        setFancySubmitClass('onSubmitBtnClick');
        setTimeout(function(){
            isCheckHidden.current = false;
            setFancySubmitClass('submitBtnValidate');
            setTimeout(function(){
                isCheckHidden.current = true;
                setFancySubmitClass('');
            }, 1250)
        }, 2250)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // Basic profile information can be updated on the client
        updateProfile(user, {displayName: displayName})
        .then(function() {
            fancySubmit();
            console.debug('displayName update successful')
          }).catch(function(error) {
              console.log(error);
            alert("Unable to update displayName, perhaps the one entered was invalid");
          });
    }

    useEffect(() => {
        if ((user && user.uid != null) || proPicChanged) {
            setProPicChanged(false)
            var proPicRef = ref(storage, `profiles/${user.uid}/profilePic`)
            getDownloadURL(proPicRef)
            .then((url) => {
                setProPic(url)
                updateProfile(user, {photoURL: url})
                .then(function() {
                    console.debug('photoUrl update successful')
                }).catch(function(error) {
                    console.log(error);
                    alert("Unable to update photoUrl, unknown error occured");
                });
            })
            .catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    console.log("File doesn't exist");
                    break;
                case 'storage/unauthorized':
                    console.log("User doesn't have permission to access the object")
                    break;
                case 'storage/unknown':
                    console.log("Unknown error occurred, inspect the server response")
                    break;
                default:
                    console.log('unexpected error occurred')
            }
            });
        }

      }, [user, proPicChanged, storage])

    useEffect(() => {
        if (user && user.displayName != null) {
            setDisplayName(user.displayName)
        }
    }, [user])

    if (userStatus === 'loading' || hasEmitted === false) {
        return <LoadingSpinner />;
      }

    return (
        <div>
            <Container >
                 <Card bg='dark' text='white' className="mx-4 mt-2 card-no-shadow">
                     <Card.Header as="h5">Profile Picture</Card.Header>
                     <Card.Body>
                         <Row>
                             <Col xs='auto'>
                                {/* this one didn't update UI after pro pic change */}
                                {/* {user['photoURL'] == null ? < FontAwesomeIcon icon={faUserCircle} color="white" size="4x"/> : <RoundImage imageUrl={user.photoURL} />} */}
                                {proPic === '' ? < FontAwesomeIcon icon={faUserCircle} color="white" size="4x"/> : <RoundImage imageUrl={proPic} />}
                             </Col>
                             <Col >
                                 <Card.Text>
                                     Must be JPEG, PNG, or GIF and cannot exceed 10MB.
                                 </Card.Text>
                                 <ImageUploadButton userId={user?.uid} updateProPicChanged={updateProPicChanged}/>
                             </Col>
                         </Row>
                     </Card.Body>
                 </Card>
                 <Card  bg='dark' className="mx-4 mt-2 card-no-shadow">
                 <Card.Header as="h5" style={{color: 'white'}}>Profile Settings</Card.Header>
                 <Form onSubmit={handleSubmit}>
                    <Row className="mb-3 justify-content-md-center">
                        <Col xs={6}>
                            <Form.Group controlId="form_display_name">
                            <FloatingLabel
                            controlId="floatingInput"
                            label="Display Name"
                            className="mb-3">
                                <Form.Control className="text-center" type="text" placeholder="Display Name" required value={displayName} onChange={e => {setDisplayName(e.target.value)}}/>
                            </FloatingLabel>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="submitBtnContainer mb-3">
                        <button  type="submit" className={`submitBtn ${fancySubmitClass}`}>{isCheckHidden.current ? "SUBMIT" : <FontAwesomeIcon icon={faCheck} color="white"/>}</button>
                    </div>
                 </Form>
                 </Card>
             </Container>
        
        
      </div>
    )
}

const UploadProgress = ({ uploadTask, storageRef }) => {
  const { status, data: uploadProgress } = useStorageTask(uploadTask, storageRef);

  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  const { bytesTransferred, totalBytes } = uploadProgress;

  const percentComplete = Math.round(100 * (bytesTransferred / totalBytes));
  console.debug(`Uploading image: ${percentComplete} complete`);
  return <ProgressBar animated now={percentComplete} variant="success" className="mt-2"/>
};

const ImageUploadButton = ({ userId, updateProPicChanged }) => {
  const [uploadTask, setUploadTask] = useState(undefined);
  const [profilePicRef, setProfilePicRef] = useState(undefined);
  const storage = useStorage();
  const onChange = event => {
    const fileList = event.target.files;
    const fileToUpload = fileList[0];
    // const fileName = fileToUpload.name;
    // const fileExtension = fileName.split('.').pop();

    // Check to see if the user clicked "cancel" instead of selecting a file to upload
    // If that is the case then there is no need to upload to GCS
    if (typeof fileToUpload === "undefined") {
        console.debug(`there was no fileToUpload: ${fileToUpload}`)
        return
    }
    if (fileToUpload.size > 10485760){
        console.debug(`fileToUpload was too big, size: ${fileToUpload.size} > 10MB (10485760 Bytes)`);
        alert("File is larger than 10MB, please try a smaller file");
        return
    }
    const newRef = ref(storage, `profiles/${userId}/profilePic`);
    setProfilePicRef(newRef);

    const uploadTask = uploadBytesResumable(newRef, fileToUpload);

    uploadTask.then(() => {
    console.debug('upload complete');
    setUploadTask(undefined);
    updateProPicChanged()
    });
    setUploadTask(uploadTask);
    
  };

  return (
    <>
      <Button variant="primary" onClick={() => document.getElementById('file').click()}>Add Profile Picture</Button>
      <input type="file" id="file" hidden accept="image/png, image/jpeg, image/gif" onChange={onChange} />
      {uploadTask && <UploadProgress uploadTask={uploadTask} storageRef={profilePicRef} /> }
    </>
  );
};

const RoundImage = ({ imageUrl }) => {
  return <Image src={imageUrl} roundedCircle style={{ width: '5em', height: '5em' }}/>
};

export default Profile